import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import Seo from '../components/Seo';
import Layout from '../components/Layout';
import { SadTear } from '@emotion-icons/fa-regular/SadTear';

const notFoundStyles = css`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  margin: 3.5rem 1rem 1rem;
  background-color: #fff;
  border-radius: 1.125rem;
  padding: 3rem 2rem;
  h1 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }
  div {
    margin: 0 auto;
  }
  p {
    font-size: 1.25rem;
  }
  a {
    color: #007aff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      text-decoration-style: wavy;
    }
  }
  @media (min-width: 40rem) {
    margin-top: 7rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 40rem;
    h1 {
      font-size: 3rem;
    }
    p {
      font-size: 1.25rem;
      line-height: 2rem;
      text-wrap: pretty;
    }
    div.about-image-wrapper {
      max-width: 50%;
      margin: 0 auto 2rem;
      display: block;
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout pageTitle='404 - Not Found' theme='light'>
      <Seo title='404' />
      <article css={notFoundStyles}>
        <header>
          <h1>Oops! Page Not Found</h1>
        </header>
        <div>
          <SadTear color='#622AFF' title='not-found' size='100' />
        </div>
        <main>
          <p>
            It looks like you've taken a wrong turn. The page you're looking for
            doesn't exist or has been moved.
          </p>
          <p>
            No worries, you can head back to the <Link to='/'>home page</Link>{' '}
            or explore other sections of my portfolio.
          </p>
        </main>
      </article>
    </Layout>
  );
};

export default NotFoundPage;
